import { ReactNode } from 'react'

import AiAnimation from 'components/AiAnimation'

import styles from './ThinkingView.module.css'

type ThinkingViewProps = {
  heading?: ReactNode | string
  subheading?: ReactNode | string
}

const ThinkingView = ({ heading = 'Finding new Sellers for you...', subheading }: ThinkingViewProps) => {
  return (
    <div className={styles.container}>
      <img className={styles.branding} src="/marketplace/fieldfolio-ai.svg" alt="fieldfolio-ai" />

      <h1 className={styles.heading}>{heading}</h1>
      {subheading ? (
        subheading
      ) : (
        <p className={styles.subhead}>
          We&apos;re now preparing some Seller recommendations for you.
          <br />
          You can request wholesale pricing from your recommended Sellers in the next step.
        </p>
      )}
      <AiAnimation />
    </div>
  )
}

export default ThinkingView
