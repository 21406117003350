import { MouseEvent, useState } from 'react'
import classnames from 'classnames'

import { useApollo } from 'lib/apolloClient'

import Button from 'components/Button'
import { useToast } from 'components/Toast'
import { ButtonProps } from 'components/Button/Button'

import useSendRepPricingRequest from './useSendRepPricingRequest'
import { RepPricingRequestSourceEnum } from '../../../../../__generated__/globalTypes'

import styles from './RepRequestRecommendedSellersButton.module.css'

export type SellersListProps = {
  sellerId: string
  catalogIds: string[]
}[]

type RepRequestRecommendedSellersButtonProps = {
  className?: string
  label?: string
  successLabel?: string
  showSuccessLabel?: boolean
  onPricingRequestSent?: () => void
  sellersList: SellersListProps
  source?: RepPricingRequestSourceEnum
} & ButtonProps

const RepRequestRecommendedSellersButton = ({
  className,
  kind = 'default',
  label,
  icon,
  successLabel = 'Pricing Requested',
  showSuccessLabel = false,
  onPricingRequestSent,
  sellersList,
  source,
  onClick,
  isSubmitting,

  ...rest
}: RepRequestRecommendedSellersButtonProps) => {
  const { cache } = useApollo({})
  const [showToast] = useToast()
  const [submitting, setSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const { requestRepPricing, requestRepPricingLoading } = useSendRepPricingRequest({})

  const handleRequestClick = async (event: MouseEvent<HTMLButtonElement>) => {
    setSubmitting(true)

    let result

    for (const seller of sellersList) {
      const { sellerId, catalogIds } = seller

      result = await requestRepPricing({
        variables: {
          input: { sellerId, catalogIds, source }
        },
        update: (_cache, result) => {
          if (result.data?.createRepPricingRequest.success) {
            onPricingRequestSent && onPricingRequestSent()
          }
        }
      })

      if (result.data?.createRepPricingRequest.success) {
        setIsSuccess(true)
      }

      const errors = result.data?.createRepPricingRequest.errors
      if (errors) {
        errors?.forEach(e => (e.code && e.message ? showToast({ kind: 'error', message: e.message }) : ''))
      }

      // Introduce a delay of 200ms before the next iteration
      // This helps prevent backend issue where multiple requests at once can cause backend MySQL deadlock errors
      await delay(200)
    }

    if (result?.data?.createRepPricingRequest.success) {
      onPricingRequestSent && onPricingRequestSent()
      // Clear the recommendedSellers cache for the rep
      const repId = result.data.createRepPricingRequest.repPricingRequest?.repId ?? ''
      cache.evict({ id: `Rep:${repId}`, fieldName: 'recommendedSellers' })
      cache.evict({ id: `Rep:${repId}`, fieldName: 'lastRecommendationsReviewAt' })
      cache.gc()
    }

    onClick && onClick(event)
    setSubmitting(false)
  }

  return (
    <Button
      type="submit"
      kind={kind}
      className={classnames({ [styles.isSuccess]: isSuccess && showSuccessLabel }, className)}
      icon={isSuccess && showSuccessLabel ? 'check-circle' : icon ? icon : undefined}
      iconPosition={isSuccess && showSuccessLabel ? 'left' : 'right'}
      iconColor={isSuccess && showSuccessLabel ? 'green' : undefined}
      disabled={requestRepPricingLoading || isSubmitting || submitting}
      isSubmitting={requestRepPricingLoading || isSubmitting || submitting}
      onClick={handleRequestClick}
      {...rest}>
      {/* Show successLabel if request was sent successfully, else show default label */}
      {showSuccessLabel && isSuccess
        ? successLabel
        : label
        ? label
        : `Request To Sell ${sellersList.length > 1 ? 'for All' : ''}`}
    </Button>
  )
}

export default RepRequestRecommendedSellersButton
