import { useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import classnames from 'classnames'

import { parseCountryCode } from 'lib/countries'

import Badge from 'components/Badge'
import Icon from 'components/Icon'
import SquareImage from 'components/SquareImage'

import SellerPreviewModal from '../SellerPreviewModal'
import BuyerRequestRecommendedSellersButton from '../BuyerRequestRecommendedSellersButton'
import RepRequestRecommendedSellersButton from '../RepRequestRecommendedSellersButton'

import { GetRecommendedSellersForBuyer_currentBuyer_recommendedSellers } from 'modules/recommendations/pages/SellerRecommendationPage/graphql/__generated__/GetRecommendedSellersForBuyer'
import { GetRecommendedSellersForRep_currentRep_recommendedSellers } from 'modules/recommendations/pages/SellerRecommendationPage/graphql/__generated__/GetRecommendedSellersForRep'
import { SellersListProps } from '../BuyerRequestRecommendedSellersButton/BuyerRequestRecommendedSellersButton'
import {
  AccountableTypeEnum,
  PricingRequestSourceEnum,
  RepPricingRequestSourceEnum
} from '../../../../../__generated__/globalTypes'

import styles from './PricingRequestSellerItem.module.css'

const ANIMATION_DURATION = 1800

type PricingRequestSellerItemProps = {
  className?: string
  seller:
    | GetRecommendedSellersForBuyer_currentBuyer_recommendedSellers
    | GetRecommendedSellersForRep_currentRep_recommendedSellers
  accountableType: AccountableTypeEnum.BUYER | AccountableTypeEnum.REP
  stopCardAnimation?: boolean
}

const PricingRequestSellerItem = ({
  className,
  seller,
  accountableType,
  stopCardAnimation
}: PricingRequestSellerItemProps) => {
  const nodeRef = useRef(null)
  const [isHovered, setIsHovered] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [cardStyle, setCardStyle] = useState(false)

  const { id, logoThumbUrl, displayName, marketplaceCatalogs, approvedPricingRequestCount, location } = seller
  const brandValues = seller.topBrandValues.map(bv => bv.brandValue.name) ?? []
  const sellersList: SellersListProps = [
    {
      sellerId: id,
      catalogIds: marketplaceCatalogs.nodes.map(catalog => catalog.id)
    }
  ]

  // Animate Seller card when it first appears
  useEffect(() => {
    if (cardStyle) {
      setTimeout(() => {
        setCardStyle(false)
      }, ANIMATION_DURATION)
    }
  }, [stopCardAnimation, cardStyle])

  const handleShowModal = () => {
    setShowModal(prevState => !prevState)
  }

  const toggleCardStyle = () => {
    if (stopCardAnimation) return

    setCardStyle(prevState => !prevState)
  }

  return (
    <>
      <Transition nodeRef={nodeRef} in={!cardStyle} timeout={ANIMATION_DURATION}>
        {state => {
          return (
            <div
              className={classnames(
                styles.container,
                {
                  [styles.entering]: state === 'entering' && Boolean(seller),
                  [styles.entered]: state === 'entered',
                  [styles.exiting]: state === 'exiting',
                  [styles.exited]: state === 'exited'
                },
                className
              )}>
              <div
                ref={nodeRef}
                className={styles.catalogCover}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleShowModal}>
                {isHovered && (
                  <Badge className={styles.previewBadge} kind="info" style="solid" leadingIcon="eye">
                    Preview
                  </Badge>
                )}
                <SquareImage src={logoThumbUrl || ''} className={styles.img} />
              </div>
              <div className={styles.sellerContent}>
                <p className={styles.displayName}>{displayName}</p>
                {approvedPricingRequestCount > 20 && (
                  <div className={styles.sellerMeta}>
                    <Icon className={styles.sellerMetaIcon} size={16} kind="star" />
                    {approvedPricingRequestCount} Buyers
                  </div>
                )}
                {location && (
                  <p className={styles.sellerMeta}>
                    <Icon className={styles.sellerMetaIcon} size={14} kind="map-pin" />
                    {location.city}, {location.state}, {location.country && parseCountryCode(location.country)}
                  </p>
                )}

                <div className={styles.badges}>
                  {brandValues &&
                    brandValues.map(brandValue => (
                      <Badge kind="brand" style="tinted" className={styles.badge} key={brandValue}>
                        {brandValue}
                      </Badge>
                    ))}
                </div>

                <div className={styles.buttonContainer}>
                  {accountableType === AccountableTypeEnum.BUYER && (
                    <BuyerRequestRecommendedSellersButton
                      className={styles.requestPricingButton}
                      label="Request Pricing"
                      sellersList={sellersList}
                      source={PricingRequestSourceEnum.RECOMMENDATION}
                      onPricingRequestSent={toggleCardStyle}
                      showSuccessLabel={cardStyle}
                      isBlock
                    />
                  )}

                  {accountableType === AccountableTypeEnum.REP && (
                    <RepRequestRecommendedSellersButton
                      className={styles.pricingRequestButton}
                      sellersList={sellersList}
                      source={RepPricingRequestSourceEnum.RECOMMENDATION}
                      onPricingRequestSent={toggleCardStyle}
                      showSuccessLabel={cardStyle}
                      isBlock
                    />
                  )}
                </div>
              </div>
            </div>
          )
        }}
      </Transition>

      <SellerPreviewModal
        seller={seller}
        isOpen={showModal}
        onExit={handleShowModal}
        accountableType={accountableType}
      />
    </>
  )
}

export default PricingRequestSellerItem
