import { useState, useEffect, useCallback, ChangeEvent } from 'react'
import { useRouter } from 'next/router'
import classnames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'
import qs from 'qs'

import setUrlParams from 'lib/set-url-params'

import Search from 'components/Icon/Search'

import formElementStyles from 'components/FormControls/FormControls.module.css'
import styles from './SellerPreviewProductSearch.module.css'

const TIMEOUT_DELAY = 1000

type SellerPreviewProductSearchProps = {
  className?: string
  isAutoFocus?: boolean
  placeholder?: string
}

const SellerPreviewProductSearch = ({ className, isAutoFocus, placeholder }: SellerPreviewProductSearchProps) => {
  const router = useRouter()
  const [searchQuery, setSearchQuery] = useState<string>('')

  // If a search query is present in the URL, reflect the value in the search input.
  useEffect(() => {
    if (router.isReady && router.query.search) {
      setSearchQuery(String(router.query.search))
    }
  }, [router.isReady, router.query.search])

  const updateUrlQuery = useDebouncedCallback(
    useCallback(
      (query: string) => {
        const url = new URL(document.location.href, document.location.origin)
        const parts = qs.parse(url.search.replace('?', ''))
        const params: Record<string, string | string[] | undefined | null | boolean> = { ...parts, search: query }
        setUrlParams(params)

        const href = `${url.origin}${url.pathname}?${qs.stringify(params)}`.replace(/\?$/, '')
        router.push(href)
      },
      [router]
    ),
    TIMEOUT_DELAY
  )

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value
    setSearchQuery(search)
  }

  return (
    <form className={classnames(styles.form, className)} action="">
      {/* Input field for mobile */}
      <input
        className={classnames(styles.searchInput, formElementStyles.fieldInput, styles.inputTextForMobile)}
        autoFocus={isAutoFocus}
        type="search"
        value={searchQuery}
        placeholder={placeholder ? placeholder : `Search products`}
        onChange={handleOnChange}
        onKeyUp={() => updateUrlQuery(searchQuery)}
      />

      {/* Input field for tablet and desktop */}
      <input
        className={classnames(styles.searchInput, formElementStyles.fieldInput, styles.inputTextForTablet)}
        autoFocus={isAutoFocus}
        type="search"
        value={searchQuery}
        placeholder={placeholder ? placeholder : `Search products`}
        onChange={handleOnChange}
        onKeyUp={() => updateUrlQuery(searchQuery)}
      />
      <button className={styles.searchIconButton} type="submit">
        <div className={styles.searchIcon}>
          <Search />
        </div>
      </button>
    </form>
  )
}

export default SellerPreviewProductSearch
