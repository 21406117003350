import classNames from 'classnames'
import GridContainer from 'modules/marketplace/layout/components/GridContainer'
import PricingRequestSellerItem from '../PricingRequestSellerItem'

import { GetRecommendedSellersForBuyer_currentBuyer_recommendedSellers } from 'modules/recommendations/pages/SellerRecommendationPage/graphql/__generated__/GetRecommendedSellersForBuyer'
import { GetRecommendedSellersForRep_currentRep_recommendedSellers } from 'modules/recommendations/pages/SellerRecommendationPage/graphql/__generated__/GetRecommendedSellersForRep'
import { AccountableTypeEnum } from '../../../../../__generated__/globalTypes'

import styles from './SellerGrid.module.css'

type SellerGridProps = {
  className?: string
  sellers:
    | GetRecommendedSellersForBuyer_currentBuyer_recommendedSellers[]
    | GetRecommendedSellersForRep_currentRep_recommendedSellers[]
  accountableType: AccountableTypeEnum.BUYER | AccountableTypeEnum.REP
  stopCardAnimation?: boolean
}

const SellerGrid = ({ className, sellers, accountableType, stopCardAnimation }: SellerGridProps) => {
  return (
    <GridContainer className={classNames(className, styles.sellerGrid)}>
      {sellers.map((seller, i) => {
        return (
          <PricingRequestSellerItem
            key={i}
            seller={seller}
            accountableType={accountableType}
            stopCardAnimation={stopCardAnimation}
          />
        )
      })}
    </GridContainer>
  )
}

export default SellerGrid
