import { useMutation } from 'hooks/useMutation'

import BUYER_REQUEST_RECOMMENDED_SELLERS_MUTATION from './graphql/BuyerRequestRecommendedSellersMutation.graphql'
import {
  BuyerRequestRecommendedSellersMutation,
  BuyerRequestRecommendedSellersMutationVariables
} from './graphql/__generated__/BuyerRequestRecommendedSellersMutation'

type UseSendBuyerPricingRequestProps = {
  onSuccess?: () => void
}

const useSendBuyerPricingRequest = ({ onSuccess }: UseSendBuyerPricingRequestProps) => {
  const [requestBuyerPricing, { loading: requestBuyerPricingLoading }] = useMutation<
    BuyerRequestRecommendedSellersMutation,
    BuyerRequestRecommendedSellersMutationVariables
  >(BUYER_REQUEST_RECOMMENDED_SELLERS_MUTATION, {
    onCompleted: data => {
      if (data.createBuyerPricingRequest.success) {
        onSuccess?.()
      }
    },
    onError: error => {
      console.error('Failed to create buyer pricing request', error)
    }
  })

  return {
    requestBuyerPricing,
    requestBuyerPricingLoading
  }
}

export default useSendBuyerPricingRequest
