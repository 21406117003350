import { MouseEvent, useState } from 'react'
import classnames from 'classnames'

import { useApollo } from 'lib/apolloClient'

import Button from 'components/Button'
import { useToast } from 'components/Toast'
import { ButtonProps } from 'components/Button/Button'

import useSendBuyerPricingRequest from './useSendBuyerPricingRequest'
import { PricingRequestSourceEnum } from '../../../../../__generated__/globalTypes'

import styles from './BuyerRequestRecommendedSellersButton.module.css'

export type SellersListProps = {
  sellerId: string
  catalogIds: string[]
}[]

type BuyerRequestRecommendedSellersButtonProps = {
  className?: string
  label?: string
  successLabel?: string
  showSuccessLabel?: boolean
  onPricingRequestSent?: () => void
  sellersList: SellersListProps
  source?: PricingRequestSourceEnum
} & ButtonProps

const BuyerRequestRecommendedSellersButton = ({
  className,
  kind = 'default',
  label,
  icon,
  successLabel = 'Pricing Requested',
  showSuccessLabel = false,
  onPricingRequestSent,
  sellersList,
  source,
  onClick,
  isSubmitting,

  ...rest
}: BuyerRequestRecommendedSellersButtonProps) => {
  const { cache } = useApollo({})
  const [showToast] = useToast()
  const [submitting, setSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const { requestBuyerPricing, requestBuyerPricingLoading } = useSendBuyerPricingRequest({})

  const handleRequestClick = async (event: MouseEvent<HTMLButtonElement>) => {
    setSubmitting(true)

    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

    let result

    for (const seller of sellersList) {
      const { sellerId, catalogIds } = seller

      result = await requestBuyerPricing({
        variables: {
          input: { sellerId, catalogIds, source }
        }
      })

      if (result.data?.createBuyerPricingRequest.success) {
        setIsSuccess(true)
      }

      const errors = result.data?.createBuyerPricingRequest.errors
      if (errors) {
        errors?.forEach(e => (e.code && e.message ? showToast({ kind: 'error', message: e.message }) : ''))
        continue
      }

      // Introduce a delay of 200ms before the next iteration
      // This helps prevent backend issue where multiple requests at once can cause backend MySQL deadlock errors
      await delay(200)
    }

    if (result?.data?.createBuyerPricingRequest.success) {
      onPricingRequestSent && onPricingRequestSent()
      // Clear the recommendedSellers cache for the buyer
      const buyerId = result.data.createBuyerPricingRequest.buyerPricingRequest?.buyerId ?? ''
      cache.evict({ id: `Buyer:${buyerId}`, fieldName: 'recommendedSellers' })
      cache.evict({ id: `Buyer:${buyerId}`, fieldName: 'lastRecommendationsReviewAt' })
      cache.gc()
    }

    onClick && onClick(event)
    setSubmitting(false)
  }

  return (
    <Button
      type="submit"
      kind={kind}
      className={classnames({ [styles.isSuccess]: isSuccess && showSuccessLabel }, className)}
      icon={isSuccess && showSuccessLabel ? 'check-circle' : icon ? icon : undefined}
      iconPosition={isSuccess && showSuccessLabel ? 'left' : 'right'}
      iconColor={isSuccess && showSuccessLabel ? 'green' : undefined}
      disabled={requestBuyerPricingLoading || isSubmitting || submitting}
      isSubmitting={requestBuyerPricingLoading || isSubmitting || submitting}
      onClick={handleRequestClick}
      {...rest}>
      {/* Show successLabel if request was sent successfully, else show default label */}
      {showSuccessLabel && isSuccess
        ? successLabel
        : label
        ? label
        : `Request Wholesale Pricing ${sellersList.length > 1 ? 'for All' : ''}`}
    </Button>
  )
}

export default BuyerRequestRecommendedSellersButton
