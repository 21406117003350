import classNames from 'classnames/bind'
import styles from './AiAnimation.module.css'

const cx = classNames.bind(styles)

const AiAnimation = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className={cx(styles.star, styles.one)}
      d="M34 10L36 3L38 10L44 12L38 14L36 21L34 14L28 12L34 10Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      className={cx(styles.star, styles.two)}
      d="M11.5 21.7778L14 14L16.5 21.7778L24 24L16.5 26.2222L14 34L11.5 26.2222L4 24L11.5 21.7778Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      className={cx(styles.star, styles.three)}
      d="M30.25 35.4444L32 30L33.75 35.4444L39 37L33.75 38.5556L32 44L30.25 38.5556L25 37L30.25 35.4444Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
)
export default AiAnimation
