import { ReactNode } from 'react'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { Scrollbar } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'

import classnames from 'classnames'

import styles from './SwipeView.module.css'

type SwipeViewProps = {
  className?: string
  slideClassName?: string
  children: ReactNode[]
  disabled?: boolean
} & SwiperProps

const SwipeView = ({
  className,
  slideClassName,
  children,
  disabled = false,
  modules = [Scrollbar],
  slidesPerView = 2,
  spaceBetween = 10,
  onReachBeginning,
  onReachEnd,

  ...props
}: SwipeViewProps) => {
  return (
    <Swiper
      className={classnames(styles.swiperContainer, className)}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      preloadImages={false}
      lazy
      scrollbar={{
        enabled: true,
        hide: false,
        draggable: true
      }}
      onReachBeginning={onReachBeginning}
      onReachEnd={onReachEnd}
      modules={modules}
      enabled={!disabled}
      grabCursor
      {...props}>
      {children?.map((child: ReactNode, index: number) => {
        return (
          <SwiperSlide className={slideClassName} key={index}>
            {child}
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default SwipeView
