import classnames from 'classnames'
import Icon from 'components/Icon'

import styles from './NoResultsFound.module.css'

interface NoResultsFoundProps {
  classNames?: string
  icon?: string
  iconColor?: string
  iconSize?: number
  header?: string
  subtitle?: string
}

const NoResultsFound = ({
  classNames,
  icon = 'alert-circle',
  iconColor = 'var(--colorBlue6)',
  iconSize = 72,
  header,
  subtitle
}: NoResultsFoundProps) => {
  return (
    <div className={classnames(styles.container, classNames)}>
      <Icon kind={icon} color={iconColor} size={iconSize} className={styles.icon} strokeWidth={0.8} />
      <h1 className={styles.header}>{header ?? 'No results found.'}</h1>
      <p className={styles.subtitle}>
        {subtitle ?? 'Try changing your search filter options or clear search filters.'}
      </p>
    </div>
  )
}

export default NoResultsFound
