import { useMutation } from 'hooks/useMutation'

import REP_REQUEST_RECOMMENDED_SELLERS_MUTATION from './graphql/RepRequestRecommendedSellersMutation.graphql'
import {
  RepRequestRecommendedSellersMutation,
  RepRequestRecommendedSellersMutationVariables
} from './graphql/__generated__/RepRequestRecommendedSellersMutation'

type UseSendRepPricingRequestProps = {
  onSuccess?: () => void
}

const useSendRepPricingRequest = ({ onSuccess }: UseSendRepPricingRequestProps) => {
  const [requestRepPricing, { loading: requestRepPricingLoading }] = useMutation<
    RepRequestRecommendedSellersMutation,
    RepRequestRecommendedSellersMutationVariables
  >(REP_REQUEST_RECOMMENDED_SELLERS_MUTATION, {
    onCompleted: data => {
      if (data.createRepPricingRequest.success) {
        onSuccess?.()
      }
    },
    onError: error => {
      console.error('Failed to create rep pricing request', error)
    }
  })

  return {
    requestRepPricing,
    requestRepPricingLoading
  }
}

export default useSendRepPricingRequest
